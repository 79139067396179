import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { v4 as uuidv4 } from 'uuid';
var myuuid = uuidv4();
var SESSION_EXPIRY = 1800000; // Session expiry time in milliseconds

/**
 * Checks if a session is invalid based on its expiration timestamp.
 * @param {string | null | undefined} sessionIdExpiration - The expiration timestamp of the session.
 * @returns {boolean} - Returns true if the session is invalid, false otherwise.
 */
export function isSessionInvalid(sessionIdExpiration) {
  if (sessionIdExpiration == null || sessionIdExpiration === undefined) {
    return true;
  }
  var currentTimestamp = new Date().getTime();
  return currentTimestamp > parseInt(sessionIdExpiration); // Convert to number
}

/**
 * Generates a DCF session ID.
 * @returns The generated DCF session ID.
 */
export function generateDcfSessionId() {
  var dcfsessionData = localStorage.getItem('dcfsessionData');
  var expiryTime = new Date().getTime() + SESSION_EXPIRY; // Modify the expiry time after testing in QA
  if (dcfsessionData === null || dcfsessionData === undefined || isSessionInvalid(dcfsessionData.split('|')[1])) {
    dcfsessionData = myuuid + '|' + expiryTime;
    localStorage.setItem('dcfsessionData', dcfsessionData.toString());
  } else {
    dcfsessionData = dcfsessionData.split('|')[0] + '|' + expiryTime;
    localStorage.setItem('dcfsessionData', dcfsessionData.toString());
  }
  return dcfsessionData;
}
/**
 * Retrieves the current DCF session ID.
 * @returns The current DCF session ID or null if no valid session exists.
 */
export function getCurrentDcfSessionId() {
  var dcfsessionData = localStorage.getItem('dcfsessionData');
  if (dcfsessionData === null || dcfsessionData === undefined) {
    return null;
  }
  var _dcfsessionData$split = dcfsessionData.split('|'),
    _dcfsessionData$split2 = _slicedToArray(_dcfsessionData$split, 2),
    sessionId = _dcfsessionData$split2[0],
    sessionIdExpiration = _dcfsessionData$split2[1];
  if (isSessionInvalid(sessionIdExpiration)) {
    return null;
  }
  return sessionId;
}